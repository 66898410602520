import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/bb/card"
import { css } from "@emotion/react"
import Img from "gatsby-image"

const custom_css = css`
  img{
    width:100%;
    border-radius: 15px;
  }

`;

export default function AreaInformation({ data }) {
  console.log(data)
  return (
    <Layout>
      <SEO title="About The Area" />
      <div css={custom_css}>
        <h1>About The Area</h1>
        <p>
          Boscobel, located on the lower Wisconsin River, is a gateway to the rolling hills and meandering streams of the
          unglaciated driftless region of Wisconsin. Boscobel offers a rich scenic beauty and a peaceful charm. The town is known
          for its hunting and fishing as well as being the birthplace of the Gideon Bible. Boscobel has ten public parks, including 
          30-acre Kronshage Park. Some of the best canoeing, kayaking, tubing, and fishing in the state are here on the lower Wisconsin River.
          Our Crooked Creek Cabins are conveniently located just south of the town on 450 acres. The Crooked Creek Stream, a 
          Class 1 Trout stream, is only a 20 yard walk from the cabins. Wake up in the morning &amp; walk to Crooked Creek!
        </p>

        <h2>Activities while at Crooked Creek</h2>
        <Card>
          <Img fluid={data.hunting.childImageSharp.fluid} />
          <h3>Hunting</h3>
          <p>
            The rolling hills of the Driftless Region and floodplain forests of the Wisconsin River provide a plethora of hunting options 
            virtually year round. Turkey hunting kicks off the spring in April, with seasons running through May and then a fall season picking 
            up in September. Bow deer hunting starts in mid-September, followed by the gun season in late November, and muzzleloader hunting into 
            December. Small game hunting, such as the rabbit and squirrel seasons, begins in September and runs through January. Autumn also 
            brings a number of bird seasons such as pheasant, goose, and duck.
          </p>
        </Card>
        <Card>
        <Img fluid={data.fishing.childImageSharp.fluid} />
          <h3>Trout Fishing</h3>
        <p>
          Boscobel is a trout fishing destination uniquely positioned in northern Grant County, Wisconsin, with 
          neighboring counties of Crawford and Richland just across the Wisconsin River. This area is home to nearly 
          90 DNR-designated Class 1 trout streams, among the over 170 trout streams including Class 2 and Class 3 streams. The Crooked Creek stream
          is just 20 yards from our cabins. You need a Wisconsin Trout Fishing License and Trout seasons and regulations vary, so please be sure to 
          check the DNR Wisconsin Trout Fishing pages before heading to the stream.
        </p>
        </Card>
        <Card>
        <Img fluid={data.hiking.childImageSharp.fluid} />
          <h3>Trails and Hiking</h3>
        <p>
          With Boscobel’s central location on the Lower Wisconsin Riverway, residents and visitors have access to thousands of acres of 
          public land for hiking and bird watching. The Lower Wisconsin Riverway starts at the dam at Prairie Du Sac and runs 92.3 miles 
          unimpeded by any man-made structures to the Mississippi River. On that stretch of the Wisconsin River, there are 44,000 acres 
          of accessible land, with the stretch from Spring Green to the Mississippi River known for its seclusion and scenic beauty. Wyalusing State 
          Park on the Mississippi is a 40 minute drive.
        </p>
        </Card>
        
        <h2>More Information</h2>
        <p>Further information on activities in the area can be found at the <a target="_blank" rel="noreferrer" href="https://boscobelwisconsin.com/recreation/">Boscobel City Website</a>.</p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    hunting: file(relativePath: { regex: "/hunting1/" }) {
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fishing: file(relativePath: { regex: "/fishing1/" }) {
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hiking: file(relativePath: { regex: "/hiking2/" }) {
      childImageSharp {
        fluid(maxWidth: 960, maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
